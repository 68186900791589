header.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	padding: 0;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
	z-index: 50;
}

header.extra-info {
	position: fixed;
	top:0;
	left:0;
	width:100%;
	z-index:50;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
}

header.extra-info + header.header {
	top:1.5rem;
	box-shadow: none;
}

header .active {
	border-bottom: 4px solid var(--primary);
	border-radius: 0;
	padding-top:20px;
	padding-bottom:16px;
}

header button.active {
	padding-bottom:13px;
	padding-top:16px;
}

header .shadowround {
	z-index: 9;
	position: absolute;
	left: 2.5%;
	bottom: 0px;
	width: 95%;
	height: 10px;
	border-radius: 100%;
	box-shadow: 0 3px 6px rgba(0,0,0,0.15);
}

aside .shadowround {
	z-index: 9;
	position: absolute;
	left: 2.5%;
	width: 95%;
	height: 10px;
	border-radius: 100%;
	box-shadow: 0 -3px 6px rgba(0,0,0,0.15);
}

header .mobile-btn {
	display:none;
}

@media screen and (max-width:1023px) {
	header .mobile-btn:not(.mobile-off) {
		display:block;
	}
}

@media screen and (max-width:814px) {
	header .mobile-btn {
		display:block;
	}
}
